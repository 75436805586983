/*
    1. Select museum or project
    2. Select files
    3. Create the job, save to DB
        - job ID - UUID
        - collection ID - ID
        - project ID - ID
        - user ID - ID
        - files - Array
    4. Upload files
    5. Save updated job state to DB
 */

import {useEffect, useState} from "react";
import {SelectMuseumDialog} from "../museum/SelectMuseum/SelectMuseumDialog";
import {SelectFilesDialog} from "./SelectFilesDialog";
import {damsFetch} from "../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {DocumentProvider} from "../documents/documentContext";
import {ADD_OPERATION, useAppDispatch, useAppState, useAppTranslation} from "../app/AppContext";
import {clientLog} from "../clientLog";
import {useSnackbarDispatch} from "../snackbar/SnackbarContext";
import {showSnackbar} from "../app/showSnackbar";
import {useNavigate} from "react-router-dom";

/**
 * A React component that handles the DAMS file upload workflow.
 *
 * This component guides the user through the following steps:
 * 1. Select museum or project
 * 2. Select files
 */
export const DamsFileUploadHome = () => {
    const {operations} = useAppState();
    const appDispatch = useAppDispatch();
    const snackbarDispatch = useSnackbarDispatch();
    const navigate = useNavigate();

    const [projectId, setProjectId] = useState();
    const [operation, setOperation] = useState(); // Keeps track of the current operation object.
    const [openMuseumDialog, setOpenMuseumDialog] = useState(false);
    const [showSelectFilesDialog, setShowSelectFilesDialog] = useState(false);

    const t = useAppTranslation();

    /**
     * Schedules a job for the given operation.
     *
     * @param {object} operation The id of the operation to schedule.
     */
    const scheduleOperation = async (operation) => {
        const res = await damsFetch('/jobs/file-upload', {
            method: 'POST',
            body: JSON.stringify(decamelizeKeysDeep({
                action: 'schedule',
                operation: operation
            }))
        });

        if (res.error) {
            throw new Error(res.error);
        }

        return res;
    };


    /**
     * Creates, and saves, a new upload operation.
     * @param cId
     * @param mId
     */
    const createOperation = async (cId, mId) => {
        try {
            let operationObj = {
                collectionId: cId,
                museumId: mId,
                projectId: projectId,
            };

            // Schedule the file upload operation:
            const res = await scheduleOperation(operationObj);
            const {operation: scheduledOperation} = res;

            // Register the operation in the DAMS database, to track its progress:
            const registeredOperation = await damsFetch('/jobs/file-upload', {
                method: 'POST',
                body: JSON.stringify(decamelizeKeysDeep({
                        action: 'register',
                        operation: {
                            ...scheduledOperation,
                            ...operationObj
                        }
                    }
                ))
            });

            operationObj = {
                ...operationObj,
                jobId: registeredOperation['jobId'],
                jobType: registeredOperation['jobType'],
                details: registeredOperation['messageDetails'],
                status: registeredOperation['status'],
            };

            if (registeredOperation) {
                // For local reference.
                setOperation(operationObj);

                // Add to list of ongoing operations.
                appDispatch({
                    type: ADD_OPERATION,
                    operation: operationObj
                });
            } else {
                clientLog('warn', 'failed to register file upload operation', 'damsfileuploadhome');
            }
        } catch (e) {
            if (e === 'scheduler_no_connection') {
                clientLog('warning', 'unable to connect to scheduler', 'damsfileuploadhome');
            } else {
                clientLog('error', e, 'damsfileuploadhome');
            }
            showSnackbar(snackbarDispatch, 'Filopplasting', 'Det oppstod en feil. Prøv igjen senere.', 'error');
            navigate('/');
        }
    };

    /**
     * Opens the dialog for selecting files to upload.
     */
    const openSelectFilesDialog = () => {
        setShowSelectFilesDialog(true);
    };

    const handleSelectedMuseum = async (museum) => {
        setOpenMuseumDialog(false);
        await createOperation(museum.collectionId, museum.id);
        openSelectFilesDialog();
    };

    const getSelectMuseumDialog = () => {
        if (!openMuseumDialog) {
            return <></>;
        }

        //  projectContext = false => Displays all museums
        //  projectContext = true => Displays only museums with projects module enabled
        return <SelectMuseumDialog t={t}
                                   collectionId={operation?.collectionId}
                                   dialogTitle={"Filopplasting"}
                                   selectedMuseumCallback={handleSelectedMuseum}
                                   projectContext={false}/>;
    };

    const getSelectFilesDialog = () => {
        if (!operation || !showSelectFilesDialog) {
            return <></>;
        }
        return <DocumentProvider>
            <SelectFilesDialog operation={operation}/>
        </DocumentProvider>;
    };

    /**
     * Hook used to determine which dialog to display.
     */
    useEffect(() => {
        if (!operation) {
            setOpenMuseumDialog(true);
        } else if (operation && operations.length > 0) {
            openSelectFilesDialog();
        }
    }, [operation, operations]);


    return <>
        {getSelectMuseumDialog()}
        {getSelectFilesDialog()}
    </>;
};