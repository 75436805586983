import Uppy from "@uppy/core";
import {Dashboard} from "@uppy/react";
import {DmsUppyUpload} from "@ekultur/dms-uppy-upload";
import Webcam from "@uppy/webcam";
import React, {useEffect, useState} from "react";
import {useUppyTranslations} from "./useUppyTranslations";
import {Dialog, DialogActions, DialogContent} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import {beforeUploadHandler, getMaxResolution, getUppyConfig, uploadedFilesToOperationFiles} from "./uppyFunctions";
import useDeepCompareEffect from "use-deep-compare-effect";
import {damsFetch} from "../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {useNavigate} from "react-router-dom";
import {clientLog} from "../clientLog";
import {getWorkerConfigBase} from "../webworkers/getWorkerConfigBase";
import {kickOffUploadWorker} from "../webworkers/fileuploads/kickOffUploadWorker";
import {CANCEL_OPERATION, UPDATE_OPERATION, useAppDispatch, useAppState} from "../app/AppContext";
import {DialogExtractMetadata} from "./metadata/DialogExtractMetadata";
import {getMappingConfig} from "../metadata/mappingconfig/getMappingConfig";
import {configExists} from "../metadata/mappingconfig/mappingConfigExists";

/**
 * @function SelectFilesDialog
 * @description A dialog for selecting files to upload to DAMS.
 * @param {Object} props Component props
 * @param {string} props.operation Current operation/job object.
 * @returns {ReactElement} A dialog with a Dashboard component.
 */
export const SelectFilesDialog = ({operation}) => {

    const componentName = 'selectfilesdialog';

    const locale = useUppyTranslations();
    const navigate = useNavigate();

    const {operations} = useAppState();
    const appDispatch = useAppDispatch();

    const [uppy, setUppy] = useState(undefined);

    const [extractDialogOpen, setExtractDialogOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(true);
    const [showMappingDialog, setShowMappingDialog] = useState(false);
    const [continueDisabled, setContinueDisabled] = useState(true);


    /**
     * Handles the cancel button click.
     *
     * Removes the current operation from the list of ongoing operations and
     * marks the operation "cancelled" in the database.
     *
     * Also navigates the user back to the root page.
     *
     * @returns {Promise<void>} A promise that resolves when the operation is
     * removed and the user has been navigated back to the root page.
     */
    const handleCancelClick = () => {
        // Remove from the list of ongoing operations.
        appDispatch({
            type: CANCEL_OPERATION,
            operation: operation
        });

        // Delete the operation from the database.
        damsFetch('/jobs/file-upload', {
            method: 'POST',
            body: JSON.stringify(decamelizeKeysDeep({
                action: 'cancel',
                operation: operation
            }))
        }).then(() => {
            clientLog('info', `operation: ${operation['jobId']} - cancelled by the user`, componentName);
            navigate('/', {replace: true});
        });
    };


    /**
     * Handles the continue button click.
     *
     * If the mapping dialog is supposed to be shown, it will be opened.
     * Otherwise, the `continueCallback` function will be called with the
     * operation as an argument.
     *
     * @returns {void} Nothing.
     */
    const handleContinueClick = () => {
        if (showMappingDialog) {
            setExtractDialogOpen(true);
        } else {
            continueCallback(operation);
        }
    };

    /**
     * Callback triggered when the user clicks on the "Done" button after all uploads have completed.
     */
    const handleUploadDoneClick = () => {
        handleContinueClick();
    };

    /**
     * Initiates the file upload process by kicking off the upload worker and updating the dialog state.
     *
     * @return {void}
     */
    const continueCallback = async (operation) => {
        setDialogOpen(false);

        const res = await reScheduleOperation(operation);
        const {operation: rescheduledOperation} = res;

        // Make sure the operation data is updated on the client.
        appDispatch({
            type: UPDATE_OPERATION,
            operation: rescheduledOperation
        });

        // Append changes to the list of operations, before sending it to the worker,
        // as the changes are not being absorbed by the previous call to appDispatch().
        const operationsList = [...operations];
        const ix = operationsList.findIndex(o => o.jobId === operation['jobId']);
        operationsList[ix] = rescheduledOperation;

        // Web worker job config, base-object.
        const apiGateway = window._env_.REACT_APP_DAMS_ADMIN_API;

        kickOffUploadWorker({
            workerConfig: getWorkerConfigBase(apiGateway),
            operationId: operation['jobId'],
            operations: operationsList.filter(o => o.jobType === 'FILE_UPLOAD'),
            uploadWorkerDispatch: appDispatch
        });

        navigate('/');
    };

    /**
     * Updates the operation with the list of uploaded files.
     * @param files
     * @returns {Promise<void>}
     */
    const updateDBOperation = async (files) => {
        let operationWithFiles = {
            ...operation,
            files: files
        };
        await executeApiRequest('add-files', operationWithFiles)
        return operationWithFiles;
    };

    const reScheduleOperation = async (operation) => {
        return await executeApiRequest('reschedule', operation);
    };

    /**
     * Modifies the scheduled job, by adding further job details
     * and adds the new arguments.
     *
     * @param {object} operation The id of the operation to schedule.
     */
    const modifyScheduledOperation = async (operation) => {
        return await executeApiRequest('modify', operation);
    };

    /**
     * Executes a POST request to the DAMS API to perform the specified action on the given operation.
     *
     * @param {string} action - The action to be performed on the operation (e.g. 'add-files', 'reschedule', etc.).
     * @param {object} operation - The operation object containing relevant details.
     * @return {Promise<object>} A promise that resolves with the response from the DAMS API.
     */
    const executeApiRequest = async (action, operation) => {
        return await damsFetch('/jobs/file-upload', {
            method: 'POST',
            body: JSON.stringify(decamelizeKeysDeep({
                action: action,
                operation: operation
            }))
        });
    };

    /**
     * Callback executed when all files have completed uploading.
     * @param res object    An object containing the upload result.
     */
    const uploadCompletedHandler = res => {
        if (res.failed.length > 0) {
            clientLog('warn', `operation: ${operation['jobId']} - some files failed uploading`, componentName);
            // TODO: inform the user that some files failed uploading, but that the operation will continue.
        }
        const uploadedFiles = uploadedFilesToOperationFiles(res.finished);
        updateDBOperation(uploadedFiles).then(async (operationWithFiles) => {
            // Modifying the scheduled job, by adding further job details.
            /*
            {
                "collectionId": 3,
                "museumId": 20,
                "jobId": "18b81abd-7cc0-4c92-9626-83bb0b05f93b",
                "jobType": "FILE_UPLOAD",
                "details": {
                    "operation": {
                        "jobId": "18b81abd7cc04c92962683bb0b05f93b",
                        "museumId": 20,
                        "collectionId": 3
                    }
                },
                "status": "init",
                "files": [
                    {
                        "dmsId": "0638YQH4ReNH",
                        "status": "ok",
                        "mimeType": "image/jpeg",
                        "filename": "monkey (2).jpg",
                        "size": 123909,
                        "objectType": "StillImage"
                    }
                ]
            }
             */

            await modifyScheduledOperation(operationWithFiles);

            // Dispatch an update to the list of operations held by the GUI.
            appDispatch({
                type: UPDATE_OPERATION,
                operation: operationWithFiles
            });
            setContinueDisabled(false);
        });
    };

    /**
     * Instantiates Uppy GUI.
     * @param config
     * @returns {Uppy}
     */
    const createUppy = async (config) => {
        const maxResolution = await getMaxResolution();
        const webcamConfig = {
            modes: ['picture'],
            showVideoSourceDropdown: true,
            preferredImageMimeType: 'image/jpeg',
            videoConstraints: {
                ...maxResolution,
                facingMode: 'environment',
            },
        };
        return new Uppy({
            onBeforeUpload: beforeUploadHandler,
        })
            .use(DmsUppyUpload, config)
            .use(Webcam, webcamConfig);
    };

    /**
     * Hook used to instantiate Uppy.
     */
    useDeepCompareEffect(() => {
        if (!operation) {
            return;
        }

        if (!operation['collectionId'] && !operation['projectId']) {
            return;
        }

        const uppyConfig = getUppyConfig(operation['collectionId'], uploadCompletedHandler);
        createUppy(uppyConfig).then(u => setUppy(u));
    }, [operation]);

    /**
     * Hook used to determine whether metadata mapping exists or not.
     */
    useEffect(() => {
        getMappingConfig(operation['collectionId']).then(data => {
            setShowMappingDialog(configExists(data));
        });
    }, [operation]);

    return uppy && <>
        <Dialog open={dialogOpen}>
            <DialogTitle>Filopplasting - Velg filer</DialogTitle>
            <DialogContent>
                <Dashboard uppy={uppy}
                           plugins={['DmsUppyUpload', 'Webcam']}
                           inline={true}
                           proudlyDisplayPoweredByUppy={false}
                           centerSingleFile={false}
                           doneButtonHandler={handleUploadDoneClick}
                           locale={locale}
                           disabled={!operation['collectionId']}
                           showProgressDetails={true}
                           hideCancelButton={true}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelClick}>Avbryt</Button>
                <Button onClick={handleContinueClick} variant={"contained"}
                        disabled={continueDisabled}>Fortsett</Button>
            </DialogActions>
        </Dialog>;

        {showMappingDialog && <DialogExtractMetadata operationId={operation['jobId']}
                                                     openDialog={extractDialogOpen}
                                                     callback={continueCallback}/>}
    </>
};