import {NOTIFY_FROM_WORKER} from "../../app/AppContext";

/**
 * Initiates a new file upload job by sending a notification to the client and posting a message to the upload worker.
 *
 * @param {object} workerConfig - The configuration object for the upload worker.
 * @param {string} operationId - The ID of the current operation.
 * @param {array} operations - An array of operations to be tracked by the upload worker.
 * @param {function} uploadWorkerDispatch - A dispatch function to send notifications to the client.
 * @return {void}
 */
export const kickOffUploadWorker = ({
                                        workerConfig,
                                        operationId,
                                        operations,
                                        uploadWorkerDispatch
                                    }) => {

    // Signal that we're initiating a new job.
    uploadWorkerDispatch({
        type: NOTIFY_FROM_WORKER,
        operationId: operationId,
        message: {
            id: 'workerstarted',
            details: null
        },
    });

    window._uploadWorkerInstance.postMessage(JSON.stringify({
        config: workerConfig,
        action: 'trackUploads',
        operations: operations,
        channel: 'worker',
    }));
};
