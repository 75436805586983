import {getMimeTypeFromFilename} from "../../damsFileObjectDefinitions";
import Chip from "@mui/material/Chip";
import React from "react";
import {DamsFileIcon} from "../../files/DamsFileIcon";

/**
 * Extracts the list of persons from the specified row's content.
 * @param {object} row A row from the operation files table.
 * @param {string} personType The type of person to extract, e.g. 'originator', 'donor', etc.
 * @returns {array} An array of objects with the persons.
 */
export const getPersons = (row, personType) => {
    const {content} = row;
    const {[personType]: persons} = content;
    return persons;
};

/**
 * Extracts the document icon details from the specified row's content.
 * @param {object} row A row from the operation files table.
 * @returns {object} An object containing the document icon details, including mimeType and filename.
 */
export const getDocumentIconDetails = row => {
    const {content} = row;
    const {mediae} = content;
    const {reference} = mediae[0];
    const {title: filename} = reference;
    return {
        mimeType: getMimeTypeFromFilename(filename),
        filename: filename
    };
};

/**
 * Extracts the list of subjects from the specified row's content.
 * @param {object} row A row from the operation files table.
 * @returns {array} An array of objects with the subjects.
 */
export const getSubjects = (row) => {
    const {content} = row;
    const {subjects} = content;
    return subjects;
};

/**
 * Renders a table cell with a list of person chips.
 * @param {object[]} renderedCellValue - An array of person objects.
 * @returns {JSX.Element} A table cell containing person chips.
 */
export const renderPersonCell = renderedCellValue => {
    return renderedCellValue?.map(p => (_renderChip(p.reference.title)));
};

/**
 * Renders a table cell with a file icon and filename.
 * @param {object} renderedCellValue
 * @param {string} renderedCellValue.filename
 * @param {string} renderedCellValue.mimeType
 * @returns {JSX.Element}
 */
export const renderDocumentTypeCell = (renderedCellValue) => {
    return <DamsFileIcon filename={renderedCellValue.filename}
                         mimeType={renderedCellValue.mimeType}
                         size={36}/>
};

/**
 * Renders a table cell with a list of subject chips.
 * @param {object[]} renderedCellValue - An array of subject objects.
 * @returns {JSX.Element} A table cell containing subject chips.
 */
export const renderSubjectCell = (renderedCellValue) => {
    return renderedCellValue.map(s => (_renderChip(s.reference.title)));
};

const _renderChip = label => {
    return <Chip sx={{marginRight: '4px'}}
                 color={"secondary"}
                 label={label}/>
};