import React, {useMemo} from "react";
import {Theme} from "./Theme";
import {BrowserRouter as Router} from "react-router-dom";
import {Menu} from "../menu/Menu";
import {FloatingActionButton} from "../menu/FloatingActionButton";
import {MenuTranslationProvider} from "../menu/menuTranslationContext";
import CssBaseline from "@mui/material/CssBaseline";
import {SnackbarProvider} from "../snackbar/SnackbarContext";

import {AppProvider} from "./AppContext";
import {WhenHeaderIsReady} from "../header/WhenHeaderIsReady";
import {AppHeader} from "../header/AppHeader";
import {HeaderProvider} from "../header/headerContext";
import {WithUserdata} from "../auths/WithUserdata";
import {AppContent} from "./AppContent";
import {RoutesAnonymous} from "./routes/RoutesAnonymous";
import {RoutesAuthenticated} from "./routes/RoutesAuthenticated";
import {DmsStatus} from "../dmsstatus/DmsStatus";
import {AppSnackbar} from "../snackbar/AppSnackbar";
import {ProjectProvider} from "../project/projectContext";
import {UpdateAppSnackbar} from "./UpdateAppSnackbar";
import {FileUploadProvider} from "../damsfileupload/fileUploadContext";
import {DownloadWorkerProvider} from "../workerstatus/workerStatusContext";
import {DMSStatusWorkerListener} from "../workerstatus/dmsStatusWorkerListener";
import {OperationsPopper} from "../operations/OperationsPopper";
import {UploadWorkerListener} from "../workerstatus/fileuploads/UploadWorkerListener";


export const App = () => {
    useMemo(() => {
        // Instantiates WebWorker, used when downloading files.
        window._downloadWorkerInstance = new Worker(new URL('../webworkers/downloadWorker.js', import.meta.url));

        // Instantiates WebWorker, used when fetching DMS-status.
        window._dmsStatusWorkerInstance = new Worker(new URL('../webworkers/dmsStatusWorker.js', import.meta.url));

        // Instantiate WebWorker, used when uploading files.
        window._uploadWorkerInstance = new Worker(new URL('../webworkers/fileuploads/uploadWorker.js', import.meta.url));
    }, []);

    if (!window._env_ || !window._env_.REACT_APP_API_GATEWAY) {
        return null;
    }

    return (
        <Theme>
            <CssBaseline/>
            <Router>
                <AppProvider>
                    <HeaderProvider>
                        <AppHeader/>
                        <WhenHeaderIsReady>
                            <UploadWorkerListener/>
                            <DownloadWorkerProvider>
                                <AppContent>
                                    <RoutesAnonymous/>
                                    {/* WithUserData must be used here, as the menu is operating in the context of the current user */}
                                    <WithUserdata>
                                        <MenuTranslationProvider>
                                            <UpdateAppSnackbar/>
                                            <FileUploadProvider>
                                                <ProjectProvider>
                                                    <Menu/>
                                                    <SnackbarProvider>
                                                        <DMSStatusWorkerListener/>
                                                        <DmsStatus/>
                                                        <RoutesAuthenticated/>
                                                        <AppSnackbar/>
                                                        <FloatingActionButton/>
                                                    </SnackbarProvider>
                                                </ProjectProvider>
                                            </FileUploadProvider>
                                        </MenuTranslationProvider>
                                        <OperationsPopper/>
                                    </WithUserdata>
                                </AppContent>
                            </DownloadWorkerProvider>
                        </WhenHeaderIsReady>
                    </HeaderProvider>
                </AppProvider>
            </Router>
        </Theme>
    );
};
