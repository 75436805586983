import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {OperationHeader} from "./OperationHeader";
import {OperationStage} from "./OperationStage";
import {OperationProgress} from "./OperationProgress";
import {FileUploadOperationActionButtons} from "./FileUploadOperationActionButtons";
import {DialogListFiles} from "../../damsfileupload3/metadata/DialogListFiles";

/**
 * A React component that displays details about an ongoing operation.
 *
 * @param {object} props - The component props.
 * @param {string} props.header - The header text.
 * @param {object} props.operation - The operation object.
 * @return {ReactElement} The OperationDetails component.
 */
export const FileUploadOperationDetails = ({header, operation}) => {
    const [id, setId] = useState();

    useEffect(() => {
        if (!operation) {
            return;
        }
        setId(operation['jobId']);
    }, [operation]);

    return operation
        && id
        && <Box sx={{
            padding: '8px',
            minWidth: '500px',
            minHeight: '200px',
            maxWidth: '500px',
            maxHeight: '200px',
            border: 'solid 1px #bbb',
            backgroundColor: 'white',
            borderRadius: '4px'
        }}>
            <Stack key={`file-upload-details-${id}`}
                   direction={"row"}
                   alignContent={"top"}
                   justifyContent={"space-between"}>
                <Typography variant={"subtitle1"}>{header}</Typography>
            </Stack>
            <OperationHeader operationId={id}/>
            <OperationStage operationId={id}/>
            <OperationProgress operationId={id}/>
            <FileUploadOperationActionButtons operationId={id}/>
            <DialogListFiles operationId={id}/>
        </Box>;
};